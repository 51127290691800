.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 65px;
  height: 65px;
  background: radial-gradient(
      farthest-side,
      #ff0000 0%,
      #ff0000 25%,
      transparent 0
    ),
    radial-gradient(farthest-side, #00ff00 0%, #00ff00 25%, transparent 0),
    radial-gradient(farthest-side, #0000ff 0%, #0000ff 25%, transparent 0),
    radial-gradient(farthest-side, #f3e972 0%, #f3e972 25%, transparent 0);
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  animation: spinner-kh173p 1s infinite;
}

@media (max-width: 450px) {
  .spinner {
    width: 55px;
    height: 55px;
  }
}

@keyframes spinner-kh173p {
  to {
    transform: rotate(0.5turn);
  }
}
